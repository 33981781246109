<template>
    <div class="block">
			
        <el-pagination
                @current-change="handleCurrentChange"
                :current-page="pagination.current_page"
                :page-size="pagination.per_page||pagination.pre_page"
                layout="total, prev, pager, next"
                :total="pagination.count">
        </el-pagination>
    </div>
</template>
<script>
export default {
  props:['pagination'],
  methods:{
    handleCurrentChange(val){
		// this.$parent.paginate(val);
		this.$emit("paginate", val)

	}
  }
}
</script>
