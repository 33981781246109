<template>
	<div class="page pagebrw">
		<el-row style="margin-bottom: 10px;" class="flexBetween flex_1 ">
			<div class="flex_1">
				<el-button size="small" type="primary" plain icon="el-icon-plus" @click="add">新增
				</el-button>
				<el-button size="small" type="primary" plain icon="el-icon-check" @click="showSignFor_dialog">签收
				</el-button>
			</div>

			<el-input v-model="queryForm_paifa.title" size="small" clearable style="width: 200px!important;" placeholder="标题关键词">
				<el-button slot="append" size="small" icon="el-icon-search" @click="getDstrList"></el-button>
			</el-input>
		</el-row>
		<el-table :data="dstrList" height="calc( 100% - 126px)" @selection-change="handleSelectionChange" style="width: 100%;"
		 stripe border>
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column v-if="false" label="操作" min-width="80">
				<template slot-scope="scope">
					<el-button type="text" size="mini" @click="apply_return(scope.row)">申请归还</el-button>
				</template>
			</el-table-column>
			<el-table-column type="index" label="序号" width="55"></el-table-column>
			<el-table-column prop="status" label="签收状态">
				<template slot-scope="scope">
					<span v-if="scope.row.status==0" class="st0">未签收</span>
					<span v-if="scope.row.status==1" class="st1">已签收</span>
					<span v-if="scope.row.status==2" class="st2">归还中</span>
					<span v-if="scope.row.status==3" class="st3">归还失败</span>
					<span v-if="scope.row.status==4" class="st4">已归还</span>
				</template>
			</el-table-column>
			<el-table-column prop="title" label="标题" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}
					</div>
				</template>
			</el-table-column>
			<el-table-column v-if="false" prop="sp_status" label="审批状态"></el-table-column>
			<el-table-column prop="start_date" label="领用日期" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span>{{scope.row.start_date?scope.row.start_date.substr(0,10):'-'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="zc_where" label="领用后位置" :show-overflow-tooltip="true"></el-table-column>
			<el-table-column prop="zc_desc" label="描述" :show-overflow-tooltip="true"></el-table-column>
		</el-table>
		<Pagination :pagination="page" @paginate="paginate"></Pagination>


		<!--        选择资产-->
		<el-dialog title="选择资产" :append-to-body="true" :visible.sync="dialogVisible_zcList" width="800px" custom-class="width_800 cus_dialog">
			<div>
				<el-drawer size="335px" :modal="false" :append-to-body="false" :modal-append-to-body="false" :visible.sync="drawer"
				 :with-header="false">
					<div class="drawer_box">
						<el-row style="margin-bottom: 10px;" class="flexEnd flex_1 ">
							<el-button size="small" type="primary" plain icon="el-icon-search" @click="onSearch">查询
							</el-button>
							<el-button size="small" type="primary" plain @click="onReset">重置
							</el-button>
						</el-row>
						<el-divider style="width: 100%"></el-divider>
						<el-form :model="queryForm" ref="queryForm" label-width="10px">
							<el-row>分字段搜索</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="">
										<el-input v-model="queryForm.zc_code" size="mini" placeholder="资产编码"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-input v-model="queryForm.title" size="mini" placeholder="资产名称"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-select v-model="EditItem.zc_gztype" size="mini" :disabled="disabled" placeholder="购置方式">
											<el-option label="外购" value="外购"></el-option>
											<el-option label="租赁" value="租赁"></el-option>
											<el-option label="借用" value="借用"></el-option>
											<el-option label="自行建造" value="自行建造"></el-option>
											<el-option label="其他方式" value="其他方式"></el-option>
										</el-select>
										<!--								<el-input v-model="queryForm.zc_gztype" size="mini" placeholder="购置方式"></el-input>-->
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-input v-model="queryForm.pinpai" size="mini" placeholder="品牌"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-input v-model="queryForm.xinghao" size="mini" placeholder="型号"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-input v-model="queryForm.jldw" size="mini" placeholder="计量单位"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24" class="labelCss">
									<el-form-item label="价值区间:" label-width="80px">
										<div class="flexStart">
											<el-input v-model="queryForm.jine1" size="mini" placeholder="最小值"></el-input>
											<span style="margin: 0 10px;">-</span>
											<el-input v-model="queryForm.jine2" size="mini" placeholder="最大值"></el-input>
										</div>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>筛选</el-row>
							<el-row>
								<el-col :span="12">
									<el-form-item label="">
										<el-cascader ref="myCascader1" v-model="queryForm.zc_typeid" :options="typeList" size="mini" :props="optionProps1"
										 :show-all-levels="true" filterable placeholder="资产分类" @change="handleCascaderChange1"></el-cascader>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="">
										<el-cascader ref="myCascader" v-model="queryForm.zc_storeid" :options="areaList" size="mini" :props="optionProps"
										 :show-all-levels="true" filterable placeholder="所在位置" @change="handleCascaderChange"></el-cascader>
									</el-form-item>
								</el-col>

								<el-col :span="12">
									<el-form-item label="">
										<el-date-picker v-model="queryForm.zc_date" type="date" size="mini" value-format="yyyy-MM-dd" placeholder="购置/借用日期">
										</el-date-picker>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</el-drawer>
				<div>
					<el-input v-model="queryForm.title" size="small" clearable style="width: 200px!important;" placeholder="资产名称">
						<el-button slot="append" size="small" icon="el-icon-search" @click="get_zcList"></el-button>
					</el-input>
					<el-button type="text" @click="drawer=true" style="color: #999999;margin-left: 10px;">
						<i class="el-icon-s-operation"></i>&nbsp;高级搜索
					</el-button>
				</div>
				<el-table :data="DataList" height="calc( 100% - 90px)" style="width: 100%;margin-top: 10px;" stripe border size="small">
					<el-table-column label="操作" min-width="50">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="chooseZC(scope.row)">选择</el-button>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="资产状态">
						<template slot-scope="scope">
							<span v-if="scope.row.status==0">闲置</span>
							<span v-if="scope.row.status==1">领用</span>
							<span v-if="scope.row.status==2">派发</span>
							<span v-if="scope.row.status==3">借用</span>
							<span v-if="scope.row.status==4">租赁已退还</span>
							<span v-if="scope.row.status==5">维修中</span>
							<span v-if="scope.row.status==-1">报废</span>
						</template>
					</el-table-column>
					<el-table-column prop="zc_code" label="资产编码" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="title" label="资产名称"></el-table-column>
					<el-table-column prop="zc_typeparent" label="资产分类">
						<template slot-scope="scope">
							{{scope.row.zc_typeparent}} {{scope.row.typename}}
						</template>
					</el-table-column>
					<el-table-column prop="storename" label="所在位置" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.storename3}} {{scope.row.storename2}} {{scope.row.storename}}
						</template>
					</el-table-column>
					<el-table-column prop="pinpai" label="品牌"></el-table-column>
					<el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
				</el-table>
				<Pagination :pagination="page_zcList" @paginate="paginate_zcList"></Pagination>
			</div>
			<div slot="footer">
				<el-button type="primary" @click="dialogVisible_zcList=false">关闭</el-button>
			</div>
		</el-dialog>

		<!--        派发单-->
		<el-dialog title="派发单" :append-to-body="true" :visible.sync="dialogVisible_distribute" width="1000px" custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
				<el-row style="margin-top: 20px;">
					<el-col :span="8">
						<el-form-item label="领用人:">
							<div class="flexStart">
								<el-input v-if="!disabled" v-model="EditItem.target_name" disabled="" size="mini" placeholder="请选择">
								</el-input>
								<el-button v-if="!disabled" size="mini" type="primary" plain @click="showUserDialog=true">选择
								</el-button>
							</div>
							<el-input v-if="disabled" v-model="EditItem.target_name" size="mini" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="领用日期:">
							<el-date-picker v-model="EditItem.start_date" type="date" size="mini" :disabled="disabled" value-format="yyyy-MM-dd"
							 placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="领用后位置:">
							<el-cascader v-if="!disabled" ref="myCascader2" v-model="EditItem.zc_whereid" :options="areaList" size="mini"
							 :disabled="disabled" :props="optionProps" :show-all-levels="true" filterable placeholder="请选择" @change="handleCascaderChange2"></el-cascader>
							<el-input v-if="disabled" v-model="EditItem.zc_where" size="mini" disabled></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item label="内容描述:">
							<el-input type="textarea" :autosize="{ minRows: 3}" v-model="EditItem.zc_desc" size="mini" :disabled="disabled"
							 placeholder="派发资产内容描述，可将所有物品名称填入进去"></el-input>
						</el-form-item>
					</el-col>

				</el-row>
				<el-divider style="width: 100%"></el-divider>
				<el-row>
					<el-button v-if="!disabled" size="small" type="primary" plain icon="el-icon-plus" @click="dialogVisible_zcList=true">选择资产
					</el-button>
					<div v-else>派发资产详情列表</div>
				</el-row>
				<el-row style="margin-top: 20px;">
					<el-table :data="chsZCList" border style="width: 100%" size="small">
						<el-table-column v-if="!disabled" label="操作" min-width="50">
							<template slot-scope="scope">
								<el-button type="text" @click="removeZC(scope.row)">移除</el-button>
							</template>
						</el-table-column>
						<el-table-column label="编号" type="index" width="55"></el-table-column>

						<el-table-column prop="zc_code" label="资产编码" :show-overflow-tooltip="true">
						</el-table-column>
						<el-table-column prop="title" label="资产名称"></el-table-column>
						<el-table-column prop="zc_typeparent" label="资产分类">
							<template slot-scope="scope">
								{{scope.row.zc_typeparent}} {{scope.row.typename}}
							</template>
						</el-table-column>
						<el-table-column prop="storename" label="所在位置" :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{scope.row.storename3}} {{scope.row.storename2}} {{scope.row.storename}}
							</template>
						</el-table-column>
						</el-table-column>
						<el-table-column prop="pinpai" label="品牌"></el-table-column>
						<el-table-column prop="xinghao" label="型号" :show-overflow-tooltip="true"></el-table-column>
					</el-table>
				</el-row>
			</el-form>
			<div slot="footer">
				<el-button v-if="!disabled" type="primary" @click="saveDistribute">保存</el-button>
				<el-button v-else type="primary" @click="dialogVisible_distribute=false">关闭</el-button>
			</div>
		</el-dialog>
		<!--        派发退库单-->
		<el-dialog title="派发退库单" :append-to-body="true" :visible.sync="dialogVisible_withdraw" width="1000px" custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="120px">
			</el-form>
			<div slot="footer">
				<el-button type="primary" @click="saveDistribute">保存</el-button>
			</div>
		</el-dialog>
		<!--        派发签收单-->
		<el-dialog title="派发签收单" :append-to-body="true" :visible.sync="dialogVisible_signFor" width="1000px" custom-class="width_800 cus_dialog">
			<el-form :model="EditItem" ref="EditItem" label-width="80px">
				<el-row>
					<el-form-item label="手写签名:">
						<el-upload v-if="!EditItem.handsign" action="" :http-request="uploadOss" :on-success="uploadCover"
						 :show-file-list="false" :file-list="files" name="image">
							<el-button size="mini" plain icon="el-icon-upload2" type="primary" style="margin-left: 10px;">点击上传
							</el-button>
						</el-upload>
						<div v-if="EditItem.handsign" class="flexStart">
							<img :src="EditItem.handsign" class="avatar" style="display:inline-block;width: 144px;">
							<el-tag @click="removeImg" type="danger" size="mini" style="margin-left: 20px;"><i class="el-icon-remove"></i></el-tag>
						</div>
					</el-form-item>
				</el-row>

			</el-form>
			<el-divider style="width: 100%"></el-divider>
			<el-row>
				<el-button size="small" type="primary" plain icon="el-icon-plus" @click="dialogVisible_paifaList=true">选择派发单
				</el-button>
			</el-row>
			<el-row style="margin-top: 20px;">
				<el-table :data="chPaifaList" border style="width: 100%" size="small">
					<el-table-column label="操作" min-width="50">
						<template slot-scope="scope">
							<el-button type="text" @click="removeZC(scope.row)">移除</el-button>
						</template>
					</el-table-column>
					<el-table-column label="序号" type="index" width="55"></el-table-column>
					<el-table-column prop="status" label="签收状态">
						<template slot-scope="scope">
							<span v-if="scope.row.status==0" class="st2">未签收</span>
							<span v-if="scope.row.status==1" class="st0">已签收</span>
						</template>
					</el-table-column>
					<el-table-column prop="title" label="标题" min-width="120">
						<template slot-scope="scope">
							<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="sp_status" label="审批状态"></el-table-column>
					<el-table-column prop="start_date" label="领用日期" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="zc_where" label="领用后位置" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"></el-table-column>
				</el-table>
			</el-row>
			<div slot="footer">
				<el-button type="primary" @click="saveSignFor">确认签收</el-button>
			</div>
		</el-dialog>
		<!--        选择派发记录-->
		<el-dialog title="选择派发单" :append-to-body="true" :visible.sync="dialogVisible_paifaList" width="800px" custom-class="width_800 cus_dialog">
			<div>
				<el-row style="margin-bottom: 10px;">
					<el-input v-model="queryForm_paifa.title" size="small" clearable style="width: 200px!important;" placeholder="标题关键词">
						<el-button slot="append" size="small" icon="el-icon-search" @click="getDstrList"></el-button>
					</el-input>
				</el-row>
				<el-table :data="dstrList" height="calc( 100% - 126px)" style="width: 100%;" stripe border size="small">
					<el-table-column label="操作" min-width="50">
						<template slot-scope="scope">
							<el-button type="text" size="mini" @click="choosePaifa(scope.row)">选择</el-button>
						</template>
					</el-table-column>
					<el-table-column type="index" label="序号" width="55"></el-table-column>
					<el-table-column prop="status" label="签收状态">
						<template slot-scope="scope">
							<span v-if="scope.row.status==0" class="st2">未签收</span>
							<span v-if="scope.row.status==1" class="st0">已签收</span>
						</template>
					</el-table-column>
					<el-table-column prop="title" label="标题" min-width="120">
						<template slot-scope="scope">
							<div @click="showDetails(scope.row)" style="color: #409EFF;cursor: pointer;">{{scope.row.title}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="sp_status" label="审批状态"></el-table-column>
					<el-table-column prop="start_date" label="领用日期" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="zc_where" label="领用后位置" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="remark" label="备注" :show-overflow-tooltip="true"></el-table-column>
				</el-table>
				<Pagination :pagination="page" @paginate="paginate"></Pagination>
			</div>
			<div slot="footer">
				<el-button type="primary" @click="dialogVisible_paifaList=false">关闭</el-button>
			</div>
		</el-dialog>

		<Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>
	</div>
</template>

<script>
	import Pagination from '../../com/pagination.vue'
	import Deptuser from '../../com/deptuser.vue'

	export default {
		components: {
			Pagination,
			Deptuser,
		},
		data () {
			return {
				DataList: [],
				multipleSelection: [],
				queryForm: {},
				queryForm_paifa: {},
				page: {
					current_page: 1,
					pre_page: 10,
					count: 0
				},
				page_zcList: {
					current_page: 1,
					pre_page: 10,
					count: 0
				},
				EditItem: {},
				disabled: false,
				typeList: [],
				areaList: [],
				optionProps: {value: 'id', label: 'storename', emitPath: false, checkStrictly: true},
				optionProps1: {value: 'id', label: 'cname', emitPath: false},
				files: [],
				userinfo: {},
				temp_list: [],//todo 未用备用
				showUserDialog: false,
				dialogVisible_distribute: false,
				dialogVisible_withdraw: false,
				dialogVisible_zcList: false,
				dialogVisible_paifaList: false,
				chsZCList: [],
				dstrList: [],
				dialogVisible_signFor: false,
				drawer: false,
				curDate: '',
				chPaifaList: [],
				list_url: '/api/zc_paifa_list',
				tabTitle: '派发',
			}
		},
		mounted () {
			if (this.$route.query && this.$route.query.type == 2) {
				this.list_url = '/api/zc_my_paifa_list'
				this.tabTitle = '我的派发'
			} else {
				this.list_url = '/api/zc_paifa_list'
				this.tabTitle = '派发'
			}
			this.get_zcList();
			this.getType();
			this.getArea();
			this.getDstrList();
			this.getCurDate();
			let _this = this
			if (localStorage.getItem("user")) {
				_this.userinfo = JSON.parse(localStorage.getItem("user"))
				// console.log(_this.userinfo)
			}
		},
		methods: {
			showSignFor_dialog () {
				this.chPaifaList = this.multipleSelection
				if (this.chPaifaList.length == 0) {
					this.$message.warning('请先选择一条记录')
					return
				}
				this.EditItem = {}
				this.dialogVisible_signFor = true
			},
			saveSignFor () {
				let _this = this
				let params = {}
				if ( !_this.EditItem.handsign) {
					this.$message.warning('请上传手写签名')
					return
				}
				if (_this.chPaifaList.length > 0) {
					console.log(_this.EditItem.handsign)
					_this.chPaifaList.map(s => {
						setTimeout(function () {
							_this.$http.post('/api/zc_paifa_qianshou', {
								id: s.id,
								handsign: _this.EditItem.handsign
							}).then(res => {
								if (res.data.code == 200) {
									_this.dialogVisible_signFor = false;
									_this.$message({
										type: 'success',
										message: '保存成功'
									});
									_this.getDstrList()
								} else {
									_this.$message.error(res.data.msg)
								}

							})
						}, 200)
					})
				} else {
					this.$message.warning('请先选择一条派发单')
				}
			},
			chooseZC (row) {
				console.log(row, this.chsZCList.length)

				let _this = this
				if (_this.chsZCList.length == 0) {
					_this.chsZCList.push(row)
				} else {
					if (_this.dep_Arr1(_this.chsZCList, row.id)) {
						_this.chsZCList.push(row)
					} else {
						_this.$message.warning('选择重复！')
					}
				}
			},
			dep_Arr1 (arr, id) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.id == id) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},
			removeZC (row) {
				let _this = this
				_this.chsZCList.map((z, i) => {
					if (z.id == row.id) {
						_this.chsZCList.splice(i, 1)
					}
				})
			},
			//派发签收
			choosePaifa (row) {
				let _this = this
				if (_this.chPaifaList.length == 0) {
					_this.chPaifaList.push(row)
				} else {
					if (_this.dep_Arr1(_this.chPaifaList, row.id)) {
						_this.chPaifaList.push(row)
					} else {
						_this.$message.warning('选择重复！')
					}
				}
			},
			//派发单
			saveDistribute () {
				let _this = this
				let params = {}
				params = {..._this.EditItem}
				params.usetype = 2
				let arr = []
				params.files = JSON.stringify(arr)
				if ( !params.target_name) {
					_this.$message.warning('请选择领用人')
					return;
				}
				let ids = [], titles = []
				if (_this.chsZCList.length > 0) {
					_this.chsZCList.map(s => {
						ids.push(s.id)
						titles.push(s.title)
					})
					params.zc_id = ids.join(',')
					params.title = `${titles.join(',')}派发给${params.target_name}`
					_this.$http.post('/api/zc_paifa', params).then(res => {
						_this.dialogVisible_distribute = false;
						_this.$message({
							type: 'success',
							message: '保存成功'
						});
						_this.getDstrList()
					})
				} else {
					_this.$message.warning('请选择资产！')
					return
				}

			},
			//申请归还
			apply_return (row) {
				let _this = this
				this.$confirm('是否确定归还?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/zc_back_apply", {id: row.id}).then(res => {
						this.$message.success("success");
						_this.getDstrList()
					})
				})
			},
			getDstrList () {
				let _this = this
				let params = {}
				params.page = this.page.current_page
				params.pagesize = this.page.pre_page
				if (_this.queryForm_paifa != {}) {
					for (let key in _this.queryForm_paifa) {
						params.ktype = key
						params.keyword = _this.queryForm_paifa[key]
					}
				}
				this.$http.post(_this.list_url, params).then(res => {
					this.dstrList = res.data.data;
					this.page = res.data.page
				})
			},
			get_zcList () {
				let _this = this
				let params = {}, arr = [{key: "status", value: "0"}]
				params.page = this.page.current_page
				params.pagesize = this.page.pre_page
				// params.ktype = "status"
				// params.keyword = "0"
				if (_this.queryForm != {}) {
					for (let key in _this.queryForm) {
						if (_this.queryForm[key]) {
							arr.push({key: key, value: _this.queryForm[key]})
						}
					}
					params.search = arr
				}
				this.$http.post("/api/zc_list", params).then(res => {
					if (res.data.code != 500) {
						this.DataList = res.data.data
						this.page_zcList = res.data.page
					} else {
						this.DataList = []
						this.page_zcList.current_page = 1
					}
				})
			},
			onSearch () {
				this.page_zcList.current_page = 1
				this.get_zcList()
			},
			onReset () {
				this.queryForm = {}
				this.page_zcList.current_page = 1
				this.get_zcList()
			},
			add () {
				this.dialogVisible_distribute = true
				this.disabled = false
				this.EditItem = {
					start_date: this.curDate
				}
				this.files = []
				this.chsZCList = []
			},

			dep_Arr (arr) {
				let a = true
				let fun = function (b) {
					b.map(s => {
						if (s.status != 0) {
							a = false
							return;
						}
					})
				}
				fun(arr)
				return a
			},

			uploadCover (e) {
				let _this = this
				console.log(e)
				this.EditItem.handsign = e.src
				_this.EditItem = {..._this.EditItem}
			},
			removeImg () {
				let _this = this
				this.EditItem.handsign = ''
				_this.EditItem = {..._this.EditItem}
			},
			getType () {
				let _this = this
				this.$http.post("/api/zc_type",{type:'zc'}).then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.typeList = result
					})
				})
			},
			getArea () {
				let _this = this
				this.$http.post("/api/zc_store").then(res => {
					_this.deleteEmptyGroup(res.data).then(result => {
						_this.areaList = result
					})
				})
			},
			// 处理没有children的分组
			deleteEmptyGroup (treeData) {
				return new Promise(resolve => {
					function traversal (data) {
						data.map(item => {
							for (let info in item) {
								if (item['children']) {
									if (item['children'].length > 0) {
										traversal(item['children'])
									}
									if (item['children'].length == 0) {
										delete item['children']
									}
								}
							}
						})
					}

					traversal(treeData)
					resolve(treeData)
				})
			},

			handleSelectionChange (val) {
				this.multipleSelection = val;
				console.log(val)
			},
			handleSelectionChange1 (val) {
				console.log(val)
			},
			showDetails (row) {
				let _this = this
				this.dialogVisible_distribute = true
				this.disabled = true
				this.$http.post("/api/zc_use_list", {id: row.id}).then(res => {
					console.log(res)
					res.data.zc_desc = res.data.zc_desc ? res.data.zc_desc : '-'
					_this.EditItem = {...res.data}
					_this.chsZCList = res.data.zclist
				})
			},
			paginate (val) {
				this.page.current_page = val
				this.getDstrList();
			},
			paginate_zcList (val) {
				this.page_zcList.current_page = val
				this.get_zcList();
			},
			handleCascaderChange (e) {
				console.log(e)
			},
			handleCascaderChange1 (e) {
				this.EditItem.zc_typeid = e
				let a = this.$refs['myCascader1'].getCheckedNodes()[0].data
				this.EditItem.ename = a.ename
			},
			getUser (e) {
				console.log(e)
				let _this = this
				if (e && e.length > 0) {
					_this.EditItem.target_uid = e[0].union_id
					_this.EditItem.target_name = e[0].name
					_this.showUserDialog = false
				}
			},
			closeUser () {
				this.showUserDialog = false
			},
			getCurDate () {
				let d = new Date();
				let year = d.getFullYear(); //获取完整的年份(4位)
				let month = d.getMonth() + 1; //获取当前月份(0-11,0代表1月)
				let date = d.getDate(); //获取当前日(1-31)
				d.getDay(); //获取当前星期X(0-6,0代表星期天)
				d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
				d.getHours(); //获取当前小时数(0-23)
				d.getMinutes(); //获取当前分钟数(0-59)
				d.getSeconds(); //获取当前秒数(0-59)
				d.getMilliseconds(); //获取当前毫秒数(0-999)
				d.toLocaleDateString(); //获取当前日期
				let mytime = d.toLocaleTimeString(); //获取当前时间
				d.toLocaleString(); //获取日期与时间
				this.curDate = `${year}-${month}-${date}`
			},
			handleCascaderChange2 (e) {
				// console.log(e)
				if (e) {
					let _this = this
					_this.EditItem.zc_whereid = e
					let para = [];
				for (let a of this.areaList) {
					if (a.id == e) {
						para.push(a.storename)
						break
					} else {
						if (!a.children) a.children = [];
						for (let b of a.children) {
							if (b.id == e) {
								para.push(b.storename)
								para.push(a.storename);
								break
							} else {
								if (!b.children) b.children = [];
								for (let c of b.children) {
									if (c.id == e) {
										para.push(c.storename);
										para.push(b.storename);
										para.push(a.storename);
										break
									}
								}
							}
						}
					}
				}
				this.EditItem.zc_where = "(" + e + ")" + para.reverse().join("/");
				console.log(this.EditItem)
				}
			},
			/**
			 * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
			 * id:     匹配的值
			 * data: 匹配的数组数据
			 * prop: 匹配的字段名
			 * children: 子集label
			 * cname: 输出数组需要的字段名
			 */
			getFathersById (id, data, prop, children, cname) {
				var arrRes = [], arrText = []
				const rev = (data, nodeId) => {
					for (var i = 0, length = data.length; i < length; i ++) {
						const node = data[i]
						if (node[prop] == nodeId) {
							arrRes.unshift(node[prop])
							arrText.unshift(node[prop])
							return true
						} else {
							if (node[children] && node[children].length) {
								if (rev(node[children], nodeId)) {
									arrRes.unshift(node[prop])
									arrText.unshift(node[cname])
									return true
								}
							}
						}
					}
					return false
				}
				rev(data, id)
				return arrText  //返回中文集合
			},

		}
	}
</script>

<style lang="less" type="text/scss">
	.pagebrw {
        .el-tabs__content {
            height: calc(100% - 40px);
        }
        
        .el-input, .el-select, .el-cascader {
            width: 100% !important;
        }
        
        .el-dropdown {
            vertical-align: top;
        }
        
        .el-dropdown + .el-dropdown {
            margin-left: 15px;
        }
        
        .el-icon-arrow-down {
            font-size: 12px;
        }
        
        .cus_dropdown {
            .el-button--primary {
                color: #409EFF !important;
                background-color: #Ecf5ff !important;
                border-color: #b3d8ff !important;
            }
        }
        
        .el-divider {
            margin-top: 0;
        }
        
        .el-drawer__wrapper {
            top: 15vh;
            height: 76.2vh;
        }
        
        .drawer_box {
            padding: 10px;
            
            .el-form-item {
                margin-bottom: 0px;
            }
        }
    }
 


</style>
